<template>
    <div class="tab-body-box">
        <div class="table" style="display: block">
            <Table ref="selection" highlight-row :columns="reasonDataColumns" :data="reasons" >
                <template slot-scope="{ index }" slot="序号">
                    {{ index+1 }}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <a href="javascript:" class="solution-a" @click="onEditReason('look',row)">处理方法（{{ row.solutionList.length }}）</a>
                    <a class="btn blue" href="javascript:" @click="onEditReason('edit',row)"><Icon type="ios-create" :size="22"/></a>
                    <a class="btn" href="javascript:" @click="onEditReason('del',row)"><Icon type="ios-trash" :size="22"/></a>
                </template>
                <template slot-scope="{ row,index }" slot="移动排序">
                    <a href="javascript:" v-if="(index+1)!==1" @click="onMoveReason('move',row,1)"><Icon type="ios-arrow-round-up" :size="22"/></a>
                    <a href="javascript:" v-if="(index+1)!==reasons.length" @click="onMoveReason('move',row,2)"><Icon type="ios-arrow-round-down" :size="22"/></a>
                    {{ index === 0 && reasons.length === 1?'/':'' }}
                </template>
            </Table>
        </div>
        <div class="tab-btn-body">
            <div class="box" @click="onEditReason('add')">
                <a href="javascript:">
                    <Icon type="ios-add" class="icon" :size="16"/>
                    新增
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Solution from './solution'
export default Solution
</script>
<style lang="less">
@import "solution";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-body-box"},[_c('div',{staticClass:"table",staticStyle:{"display":"block"}},[_c('Table',{ref:"selection",attrs:{"highlight-row":"","columns":_vm.reasonDataColumns,"data":_vm.reasons},scopedSlots:_vm._u([{key:"序号",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"操作",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"solution-a",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onEditReason('look',row)}}},[_vm._v("处理方法（"+_vm._s(row.solutionList.length)+"）")]),_c('a',{staticClass:"btn blue",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onEditReason('edit',row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":22}})],1),_c('a',{staticClass:"btn",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onEditReason('del',row)}}},[_c('Icon',{attrs:{"type":"ios-trash","size":22}})],1)]}},{key:"移动排序",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [((index+1)!==1)?_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onMoveReason('move',row,1)}}},[_c('Icon',{attrs:{"type":"ios-arrow-round-up","size":22}})],1):_vm._e(),((index+1)!==_vm.reasons.length)?_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onMoveReason('move',row,2)}}},[_c('Icon',{attrs:{"type":"ios-arrow-round-down","size":22}})],1):_vm._e(),_vm._v(" "+_vm._s(index === 0 && _vm.reasons.length === 1?'/':'')+" ")]}}])})],1),_c('div',{staticClass:"tab-btn-body"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.onEditReason('add')}}},[_c('a',{attrs:{"href":"javascript:"}},[_c('Icon',{staticClass:"icon",attrs:{"type":"ios-add","size":16}}),_vm._v(" 新增 ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }